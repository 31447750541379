
import cookies from 'js-cookie';
import { defineComponent, ref, SetupContext, getCurrentInstance, computed, inject, watch, onMounted } from 'vue';
import Popup from 'vant/lib/popup';
import Toast from 'vant/lib/toast';
import { getLoginPara, setLoginCookie, kpnClientMap, KpnClient, setCurrentUserHeadUrl } from '@/common/login';
import { radarLog, sendLog } from '@/common/radar';
import { getKpn } from '~/common/common-params';
import { UserInfoByLogin } from '@/api/user';

enum ProtocolType {
    TermsService = 'terms_service',
    PrivacyPolicy = 'privacy_policy',
}

const TopickProtocolUrlMap = {
    [ProtocolType.TermsService]:
        'https://app.topick-app.com/agreement/service-terms?source=navigation&__launch_options__=%7B%22topBarPosition%22%3A%22fixed%22%7D&type=ktok',
    [ProtocolType.PrivacyPolicy]:
        'https://app.topick-app.com/agreement/privacy-policy?&__launch_options__=%7B%22topBarPosition%22%3A%22fixed%22%7D&type=ktok',
};

const GoogleAccountScriptId = 'google-account-script';
const GoogleScriptSrc = 'https://accounts.google.com/gsi/client';

export default defineComponent({
    components: {
        vanPopup: Popup,
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        isIos: {
            type: Boolean,
            default: false,
        },
        isAllowClose: {
            type: Boolean,
            default: false,
        },
        containerId: {
            type: String,
            default: 'login-popup-google-button',
        },
        // "小红书" 环境
        isTopick: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, ctx: SetupContext) {
        const { proxy }: any = getCurrentInstance();
        const startupData: any = proxy.$store.state.startup;
        const show = ref(false);

        const appName = computed(() => {
            const name = props?.isTopick ? 'Topick' : proxy.$appName;
            return name;
        });
        const headerWords = computed(() => {
            return proxy.$root.$t('k_326749').replace('${0}', appName.value);
        });
        const openWords = computed(() => {
            return proxy.$root.$t('open_kwai').replace('${0}', appName.value);
        });
        const subTitle = computed(() => {
            return proxy.$root.$t('k_326750').replace('${0}', appName.value);
        });
        const buttomTitle = computed(() => {
            return proxy.$root.$t('k_326753').replace('${0}', appName.value);
        });

        const handleConfirm = (button_type: string) => {
            sendLog({
                type: 'click',
                name: 'PWA_LOGIN_POP',
                value: {
                    button_type,
                },
            });
            if (button_type === 'Google') return;
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('confirm', { skip_to: 'app' });
        };
        const client = ref<any>();

        const getClientId = () => {
            const kpn = getKpn({ bucket: proxy.$store.state.startup.bucket, host: window.location.host });
            return props.isTopick ? kpnClientMap[KpnClient.TOPICK] : kpnClientMap[kpn as KpnClient];
        };

        const handleClose = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('close', { button_type: 'close' });
        };

        const login = async (para: any) => {
            const did = cookies.get('webDid');
            proxy.$bus.emit('isLoad', true);
            // const params = getLoginPara(proxy.$store.state.startup);

            const params = getLoginPara({
                startup: startupData,
                kpn: getKpn({ bucket: proxy.$store.state.startup.bucket, host: window.location.host }),
            });

            const res = (await proxy.$axios.$$post(
                `loginApi::/rest/o/w/user/thirdPlatformLogin?access_token=${para.credential || para.access_token}&platform=google&did=${did}`,
                params
            )) as UserInfoByLogin & { result?: number };
            setLoginCookie(res);

            if (res?.user_id || res?.userId) {
                proxy.$store.commit('userModule/setUserInfo', res);
            }

            setCurrentUserHeadUrl(res);
            handleClose();

            proxy.$bus.emit('isLoad', false);
            // 服务端service token
            if (res.result === 1 && res['kwaipro.pwa_st']) {
                await proxy.$axios.$$post(`loginApi::/rest/o/w/user/login/setCookie`, {
                    authToken: res['kwaipro.pwa.at'],
                    sid: 'kwaipro.pwa',
                    followUrl: window?.location?.href,
                });
                Toast({
                    message: proxy.$root.$t('k_326761'),
                });
                proxy?.$store?.commit?.('setIsLogin', true);
                show.value = false;
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('login', true);
                radarLog({
                    event_type: 'click',
                    category: 'KWAI_SHARE',
                    src: window.location.pathname,
                    name: 'PWA_LOGIN_SUCCESSFULLY',
                });
            } else {
                Toast({
                    message: proxy.$root.$t('k_327320'),
                });
                // eslint-disable-next-line vue/require-explicit-emits
                ctx.emit('login', false);
                radarLog({
                    event_type: 'click',
                    category: 'KWAI_SHARE',
                    src: window.location.pathname,
                    name: 'PWA_LOGIN_FAILURE',
                });
            }
            proxy.$bus.emit('sendPv');
        };

        const renderGoogleLoginButton = () => {
            const clientId = getClientId();
            const google = window?.google;
            if (!google || !clientId) {
                return;
            }

            google.accounts.id.initialize({
                client_id: clientId,
                callback: async (e: any) => {
                    if (!show.value) {
                        sendLog({
                            type: 'click',
                            name: 'PWA_GOOGLE_POPUP',
                            value: {
                                button_type: 'login',
                            },
                        });
                    }
                    await login(e);
                },
            });

            google.accounts.id.prompt((notification: any) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    // try next provider if OneTap is not displayed or skipped
                    if (notification.getSkippedReason() === 'user_cancel') {
                        sendLog({
                            type: 'click',
                            name: 'PWA_GOOGLE_POPUP',
                            value: {
                                button_type: 'close',
                            },
                        });
                    }
                    show.value = true;
                    sendLog({
                        type: 'show',
                        name: 'PWA_LOGIN_POP',
                        value: {},
                    });
                    const parent = document.getElementById(props.containerId);
                    const width = screen.width;
                    const buttonWidth = Math.floor(width - (width / 414) * 54);
                    google.accounts.id.renderButton(parent, {
                        // theme: "filled_black",
                        width: buttonWidth > 400 ? 400 : buttonWidth,
                        text: 'continue_with',
                        cancel_on_tap_outside: true,
                        locale: proxy.$root.$i18n.locale || 'en',
                        theme: 'filled_blue', // 按钮主题
                        size: 'large', // 按钮大小
                        shape: 'rectangular', // 按钮形状
                        click_listener: () => {
                            sendLog({
                                type: 'click',
                                name: 'PWA_LOGIN_POP',
                                value: {
                                    button_type: 'Google',
                                },
                            });
                        },
                        // type: 'icon'
                    });

                    // console.log(container)
                }
                if (notification.isDisplayed()) {
                    sendLog({
                        type: 'show',
                        name: 'PWA_GOOGLE_POPUP',
                        value: {},
                    });
                }
            });
        };

        const initGoogleAccountSDK = ({ onLoad }: { onLoad?: () => void }) => {
            const isLoadedScript = !!window?.google;
            if (isLoadedScript) {
                return;
            }

            const appendScript = document.createElement('script');
            appendScript.src = GoogleScriptSrc;
            appendScript.id = GoogleAccountScriptId;
            document.head.appendChild(appendScript);

            appendScript.onload = function () {
                onLoad?.();
            };
        };

        watch(
            () => props.isShow,
            newValue => {
                if (newValue) {
                    const isLoadedScript = !!window?.google;
                    if (isLoadedScript) {
                        setTimeout(() => {
                            renderGoogleLoginButton();
                        }, 0);
                        return;
                    }
                    initGoogleAccountSDK({ onLoad: renderGoogleLoginButton });
                } else {
                    show.value = false;
                }
            },
            { immediate: true }
        );

        const openService = (type: ProtocolType) => {
            if (props.isTopick) {
                window.open(TopickProtocolUrlMap[type]);
                return;
            }

            const language = ['pt-BR', 'es', 'ar', 'id'];
            const path = language.includes(proxy.$root.$i18n.locale) ? `/${proxy.$root.$i18n.locale}` : '';
            const url = proxy.$isSnack ? `https://www.snackvideo.com${path}/legal?id=${type}` : `https://www.kwai.com${path}/legal?id=${type}`;
            window.open(url);
        };

        return {
            show,
            headerWords,
            client,
            openWords,
            buttomTitle,
            subTitle,
            startupData,
            ProtocolType,
            handleConfirm,
            handleClose,
            openService,
        };
    },
});
